.my-data-section-header-container {
  margin-bottom: 36px;
}

.my-data-section-header {
  font-weight: 500;
  letter-spacing: 0.05em;
}

.my-data-input-container {
  max-width: 670px;
  width: 100%;
}

.my-data-button-container {
  margin-top: 42px;
  margin-bottom: 54px;
  width: 100%;
}
