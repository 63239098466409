.edit-client-screen-section {
  margin-top: 36px;
}

.edit-client-screen-section > h5 {
  font-weight: bold;
  font-size: 18px;
}

.edit-client-screen-consultant-name {
  display: flex;
  align-items: center;
}

.edit-client-screen-consultant-name > p {
  font-size: 16px;
  margin-right: 28px;
}

.edit-client-screen-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 40px;
  background: linear-gradient(180deg, #d3cd31 0%, #efe961 100%);

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
}

.edit-client-screen-restore {
  background: linear-gradient(180deg, #8bc730 0%, #9bea5d 100%);
  margin-top: 18px;
}

.edit-client-screen-delete-warning {
  margin-top: 8px;
  font-size: 16px;
  width: 680px;
}

.edit-client-screen-delete {
  background: linear-gradient(180deg, #ad3b65 0%, #cf6a8f 100%);
  margin-top: 18px;
  color: white;
}

.change-consultant-popup-container,
.restore-client-popup,
.permanently-delete-client-confirmation-popup {
  width: 600px;
}

.change-consultant-popup-searchbar-container {
  width: 450px;
  margin-left: 7px !important;
  margin-bottom: 32px;
  display: flex;
}

.change-consultant-popup-searchbar {
  width: 100%;
}

.update-consultant-confirmation > h3 {
  width: 600px;

  margin-bottom: 16px;
  line-height: initial;
  font-weight: bold;
  font-size: 18px;
}

.update-consultant-confirmation > p {
  margin-bottom: 24px;
  font-size: 16px;
}

.update-consultant-confirmation-popup {
  margin-top: -8px;
  margin-bottom: 16px;
}

#change-consultant-file,
#delete-consultant-file {
  display: none;
}

.change-consultant-file-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 59px;
  color: white;
  cursor: pointer;

  width: 300px;

  background: linear-gradient(180deg, #6c367a 0%, #955ba4 100%);
  /* drop-shadow-1 */

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
  margin-right: 16px;
}

.change-consultant-label-container {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.change-consultant-label-container > p {
  text-overflow: ellipsis;
}
