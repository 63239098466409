.form-button-container {
  width: 100%;
  max-width: 300px;
  min-height: 42px;

  border-radius: 20px;
  font-size: 22px;

  letter-spacing: 0.025em;

  background: var(--linear-identity-1);
  color: var(--base-1);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: width 0.2s;
  filter: var(--drop-shadow-1);
}

.success-button {
  background: var(--linear-success-1);
  color: var(--base-10);
}

.danger-button {
  background: var(--linear-danger-1);
  color: var(--base-10);
}

.form-button-container > div {
  height: 20px;
}

.form-button-loading {
  width: 100%;
}
