.consultant-details-side-card-container {
  background-color: var(--identity-1);
  padding: 18px;
}

.consultant-details-photo-container {
  background-color: var(--identity-5);
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-0);
}

.item-thin-trace {
  background-color: var(--identity-7);
  height: 1px;
  min-width: 5px;
  border-radius: 1px;
  margin-top: 0.5rem;
}

.consultant-details-extend-trial-button {
  width: 100%;
  max-width: 300px;
  min-height: 32px;

  border-radius: 20px;
  font-size: 18px;

  letter-spacing: 0.025em;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: width 0.2s;
  filter: var(--drop-shadow-1);
}

.consultant-details-icon {
  font-size: 16px !important;
}

.consultant-details-client-count {
  font-size: 48px;
  line-height: 48px;
  color: var(--success-5);
}

.consultant-details-client-info {
  color: var(--identity-7);
  font-weight: 500;
  min-height: 64px;
}

.consultant-details-client-ideal {
  color: var(--identity-8);
}

.edit-consultant-email-popup-container {
  width: 500px;
}

.edit-consultant-email-popup-container > .edit-consultant-email-popup-subtitle {
  font-size: 26px;
}

.edit-consultant-email-popup-container > .edit-consultant-email-popup-warning {
  text-align: center;
  margin: 24px 0;

  font-size: 18px;
}
