:root {
  --toastify-icon-color-error: var(--error-2);
  --toastify-icon-color-success: var(--success-3);
  --toastify-icon-color-warning: var(--warning-2);
}

.Toastify__progress-bar--error {
  background-color: var(--error-2);
}

.Toastify__progress-bar--success {
  background-color: var(--success-3);
}

.Toastify__progress-bar--warning {
  background-color: var(--warning-2);
}
