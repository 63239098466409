.add-new-indication-screen-button {
  color: #ffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 40px;
  background: linear-gradient(180deg, #6c367a 0%, #955ba4 100%);

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
}
.add-new-indication-text-button {
  font-weight: 500;
  margin-top: 15px;
}
.add-new-indication-screen-button-edit {
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 40px;
  background: linear-gradient(180deg, #d3cd31 0%, #efe961 100%);
  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
}
.add-new-indication-text-button-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 100;
}
.add-new-indication-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  color: #6f2033;
}
