.cancellations-add-icon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--identity-7);
  color: var(--base-1);
}

.search-wasnot-clicked-message {
  text-align: center;
}

.cancellations-add-reason-item-button {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--identity-7);
}
