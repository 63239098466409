/* container styles */

.subscription-popup-container {
  display: flex;
  flex-direction: column;
}

/* menu styles */

.subscription-popup-menu {
  align-self: center;
  width: 736px;
  padding: 0 30px 0 30px;
}

.subscription-popup-menu-options {
  display: flex;
}

.subscription-popup-menu-option {
  cursor: pointer;
  flex: 1;
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid var(--base-5);
  color: var(--base-5);
  margin-top: 10px;
}

.subscription-popup-menu-option.selected {
  color: var(--identity-6);
  border-color: var(--identity-6);
}

/* create menu styles */
.subscription-popup-createmenu {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.subscription-popup-createmenu-text {
  text-align: center;
}

.subscription-popup-createmenu-dropdowns {
  display: flex;
  gap: 22px;
  padding: 20px 20px 0px 20px;
}
.subscription-popup-createmenu-input {
  display: flex;
  padding: 4px 20px 0px 20px;
}

.subscription-popup-createmenu-dropdown {
  flex: 1;
}

.subscription-popup-createmenu-dropdown::placeholder {
  color: black;
}

.react-select__placeholder {
  color: black !important;
}

.subscription-popup-createmenu-submit {
  background: linear-gradient(180deg, #8bc730 0%, #9bea5d 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 59px;

  width: 240px;
  height: 37px;

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
  align-self: center;
}

/* extend menu styles */

.subscription-popup-extendmenu {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.subscription-popup-extendmenu-text {
  text-align: center;
}

.subscription-popup-extendmenu-dropdowns {
  display: flex;
  gap: 22px;
  padding: 20px;
}

.subscription-popup-extendmenu-dropdown {
  width: 240px;
  margin: 0 auto;
}

.subscription-popup-extendmenu-dropdown::placeholder {
  color: black;
}

.react-select__placeholder {
  color: black !important;
}

.subscription-popup-extendmenu-submit {
  background: linear-gradient(180deg, #6c367a 0%, #955ba4 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 49px;

  width: 240px;
  height: 37px;

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
  align-self: center;
  color: white;
}

/* cancel menu styles */

.subscription-popup-cancelmenu {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 200px;
}

.subscription-popup-cancelmenu-text {
  text-align: center;
}

.subscription-popup-cancelmenu-buttons {
  display: flex;
  gap: 22px;
  justify-content: center;
  margin-top: 20px;
}

.subscription-popup-cancelmenu-submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;

  width: 270px;
  height: 37px;

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 20px;
  align-self: center;
}

.subscription-schedule-btn {
  background: linear-gradient(180deg, #d3cd31 0%, #efe961 100%);
  color: black;
}

.subscription-cancel-btn {
  background: linear-gradient(180deg, #ad3b65 0%, #cf6a8f 100%);
  color: white;
}

/* table container */

.subscription-popup-table {
  width: 780px;
  margin-top: 20px;
}

/* subtitles styles */

.subtitles-label {
  margin: 12px 0;
}

.subtitles-container {
  display: flex;
  gap: 16px;
}

.subtitles-item {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 12px;
}

.subtitle-ball {
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

/* popup confirm message */

.subscription-popup-confirmmessage {
  text-align: center;
}

.subscription-confirm-btn {
  background: var(--linear-success-1);
  color: black;
}
