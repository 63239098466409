.basic-table-container {
  filter: var(--drop-shadow-1);
  display: inline-block;
  min-width: 100%;
}

.basic-table {
  border-radius: 10px 10px 0px 0px;
  margin: auto;
  width: 100%;
  height: 100%;
}

.basic-table-header {
  background-color: var(--identity-1);
  color: var(--identity-8);
  font-weight: 500;
  height: 100%;
}

.basic-table-header-radius-left {
  border-radius: 10px 0 0 0;
}
.basic-table-header-radius-right {
  border-radius: 0 10px 0 0;
}

.basic-table-border {
  border-top: 0.5px solid var(--identity-1);
}

.basic-table-pagination {
  background-color: var(--identity-1);
  border-radius: 0px 0px 10px 10px;
  padding: 16px;
  display: flex;
  justify-content: center;
  width: 100% !important;
}

.basic-table-clickable-data {
  text-decoration: underline;
  cursor: pointer;
}

.basic-table-data-content {
  display: flex;
}

.basic-table-add-button-container {
  display: flex;
  justify-content: right;
}

.basic-table-add-button {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-top: -40px;

  background: var(--identity-7);
}

.basic-table-add-icon {
  font-size: 40px !important;
  color: var(--base-1);
}

.basic-table-expand-button {
  margin-left: 8px;
  margin-top: -8px;
  margin-right: -8px;
}

.basic-table-expand-icon {
  font-size: 22px !important;
  color: var(--base-10);
  transform: rotate(45deg);
}

.basic-table-sort-icon {
  font-size: 22px !important;
}
