.form-input {
  width: 100%;
  height: 32px;

  background: var(--base-1);
  border: 1px solid var(--identity-4);
  box-sizing: border-box;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0px 10px;
  font-size: 14px;
}
.form-input-white-background {
  background: white !important;
}
.form-input-text-area {
  width: 100%;
  min-height: 32px;

  background: var(--base-1);

  border: 1px solid var(--identity-4);
  box-sizing: border-box;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 12px 12px;
  font-size: 14px;
}

.form-input:focus,
.form-input-text-area:focus {
  outline: 1px solid var(--identity-6);
}

.form-input:focus-within,
.form-input-text-area:focus-within {
  border: 1px solid var(--identity-6);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-input-eye {
  position: relative;
  left: 264px;
  top: -28px;
  cursor: pointer;
}

.form-label {
  margin-bottom: 12px;
  line-height: 26px;
}

.form-input-container {
  margin-bottom: 32px;
  width: 100%;
}

/* .react-select__control {
    border: 50%;
    border-radius: 20px !important;
    border: 1px solid var(--identity-4);
    font-size: 14px;
    height: 32px;
  } */

/* &__indicator-separator {
    display: none;
  }

  &__menu {
    border-radius: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
  } */
