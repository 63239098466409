.progress-bar-container {
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  margin-right: 18px;
  max-width: 400px;
}

.progress-bar-filler {
  height: 100%;
  border-radius: inherit;
  text-align: right;
}

.progress-bar-ideal-line {
  height: 150%;
  background-color: transparent;
  border-right: 1px dashed var(--error-5);
  text-align: right;
  margin-top: -25px;
  display: flex;
}

.progress-bar-ideal-text {
  height: 150%;
  width: 150%;
  min-width: 120px;
  background-color: transparent;
  align-self: end;
  justify-self: end;
  margin-bottom: -44px;
  margin-right: -40px;
}
