.search-bar-container {
  display: inline-block;
  background-color: var(--identity-1);
  border-radius: 10px;
  filter: var(--drop-shadow-1);
  margin-left: auto;
  margin-right: 0px;
  padding: 8px;
  width: 100%;
}

.search-bar-input {
  background-color: var(--identity-1);
  font-size: 14px;
}

.search-bar-input:focus {
  outline: none;
}

.form-input:focus-within {
  border: 0px;
}

.search-bar-category-icon {
  font-size: 20px !important;
  color: var(--base-9);
  margin-right: 12px;
  margin-left: 4px;
}
