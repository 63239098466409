.sales-dashboard-right-icon {
  font-size: 22px !important;
  color: var(--identity-7);
}

.sales-dashboard-see-all-text {
  color: var(--identity-7);
  text-decoration: underline;
}

.sales-dashboard-item-thin-trace {
  background-color: var(--identity-3);
  height: 1px;
  min-width: 5px;
  border-radius: 1px;
  margin-top: 0.5rem;
}

.sales-dashboard-client-subscription-chance-number {
  font-size: 48px;
  line-height: 48px;
  color: var(--success-5);
}

.sales-dashboard-client-subscription-chance-text {
  color: var(--identity-7);
  font-weight: 500;
  min-height: 64px;
}

.sales-dashboard-client-item {
  color: var(--identity-8);
}

.sales-dashboard-two-lines-text {
  min-height: 32px;
  max-height: 32px;
  overflow: hidden;
}

.sales-dashboard-three-lines-text {
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
}
