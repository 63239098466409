* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* ---- Colors ---- */

  --identity-1: #ffe4f8;
  --identity-2: #f5d1f5;
  --identity-3: #e3b8ee;
  --identity-4: #c292ce;
  --identity-5: #955ba4;
  --identity-6: #6c367a;
  --identity-7: #471953;
  --identity-8: #2c0d34;
  --identity-9: #280425;
  --identity-10: #1d031a;

  --base-0: #f5f5f5;
  --base-1: #f0f0f0;
  --base-2: #dbdbdb;
  --base-3: #c7c7c7;
  --base-4: #b2b2b2;
  --base-5: #9e9e9e;
  --base-6: #8a8a8a;
  --base-7: #757575;
  --base-8: #616161;
  --base-9: #4d4d4d;
  --base-10: #383838;
  --base-11: #232323;
  --base-12: #0f0f0f;

  --success-1: #ceffa8;
  --success-2: #9bea5e;
  --success-3: #8bc730;
  --success-4: #5f7a36;
  --success-5: #384d15;

  --error-1: #ee99ad;
  --error-2: #cf6a8f;
  --error-3: #ad3b65;
  --error-4: #873553;
  --error-5: #6f2033;

  --warning-0: #fff6aa;
  --warning-1: #efe961;
  --warning-2: #d3cd31;
  --warning-3: #a49516;
  --warning-4: #867909;
  --warning-5: #69600a;

  --linear-identity-1: linear-gradient(180deg, #6c367a 0%, #955ba4 100%);
  --linear-success-1: linear-gradient(180deg, #8bc730 0%, #9bea5d 100%);
  --linear-danger-1: linear-gradient(180deg, #ad3b65 0%, #cf6a8f 100%);

  --drop-shadow-1: drop-shadow(-1px 2px 4px rgba(117, 117, 117, 0.25));
}

/* ---- Fonts ---- */

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('../assets/fonts/Poppins/Poppins-Light.ttf');
  font-weight: lighter;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
  font-weight: lighter;
}

input::placeholder {
  font-family: Roboto;
}

body {
  font-family: Roboto;
  background-color: var(--base-0);
}

/* ---- Typography ---- */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--base-11);
}

h1 {
  font-size: 64px;
  line-height: 70px;
}

h2 {
  font-size: 48px;
  line-height: 52px;
}

h3 {
  font-size: 36px;
  line-height: 40px;
}

h4 {
  font-size: 28px;
  line-height: 30px;
}

p {
  font-size: 18px;
  line-height: 21px;
}

.big-paragraph {
  font-size: 22px;
  line-height: 24px;
}

.small-paragraph {
  font-size: 14px;
  line-height: 16px;
}

.font-poppins {
  font-family: Poppins;
}

/* ---- Screens Containers ---- */

@media only screen and (max-width: 820px) {
  .app-container {
    flex-direction: column;
  }
  .content-screen-container {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 821px) {
  .content-screen-container {
    height: 100vh;
    width: 100vw;
    overflow: auto;
  }
}

.general-screen-container {
  padding-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
}

.main-screen-header-container {
  margin-bottom: 48px;
}

/* ---- Icons ---- */

.whatsapp-table-data-icon {
  font-size: 20px !important;
  color: var(--success-4);
  margin-left: 4px;
}

.ballot-table-data-icon {
  font-size: 20px !important;
  color: var(--identity-7);
  margin-left: 4px;
}

.delete-card-icon {
  font-size: 28px !important;
  color: var(--error-4);
}

/* ---- Normal Table classes ---- */

.normal-table-section {
  margin-top: 36px;
}

.normal-table-title {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.normal-table-title-text {
  letter-spacing: 0.05em;
}

/* ---- Scrollbar ---- */

/* Dimensions */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--base-1);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, var(--base-3) 0%, var(--base-2) 100%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--base-4);
}
