.pagination-container {
  color: var(--identity-7);
  font-family: Poppins;
}

.pagination-button {
  border-color: var(--identity-5);
}

.pagination-button:hover {
  background-color: var(--identity-5);
  color: var(--base-0);
}

.pagination-button-selected {
  background-color: var(--identity-4);
}

.pagination-double-arrow-left {
  margin-right: -12px;
}

.pagination-arrow-icon {
  font-size: 20px !important;
  color: var(--identity-6);
}
