.testing-consultant-add-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--identity-7);
}

.testing-consultant-add-icon {
  font-size: 28px !important;
  color: var(--base-1);
}

.testing-consultant-add-contact-item-button {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--identity-7);
}
