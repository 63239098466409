.sidebar-menu-container {
  background-color: var(--identity-2);
  max-width: 280px;
  width: 100%;
  height: 100vh;
  border-radius: 0px 20px 20px 0px;
}

.sidebar-menu-mobile-container {
  background-color: var(--identity-2);
  width: 100%;
  border-radius: 0px 0px 10px 10px;
}

.sidebar-logo {
  margin-top: 18px;
  margin-left: 24px;
  margin-bottom: 14px;
}

.sidebar-logo-mobile {
  margin-top: 18px;
  margin-left: 24px;
}

.sidebar-logo-mobile-size {
  height: 30px;
}

.sidebar-font-color {
  color: var(--identity-7);
}

.sidebar-greetings-container {
  margin-left: 24px;
}

.sidebar-greetings-container-mobile {
  margin-right: 24px;
  margin-top: 18px;
  margin-left: 8px;
}

.sidebar-separator {
  margin: auto;
  width: 90%;
  height: 1px;
  background-color: var(--identity-3);
  margin-top: 8px;
}

.sidebar-category-item-container {
  margin-top: 18px;
  padding-bottom: 10px;
}

.sidebar-icon-background {
  background-color: var(--identity-4);
  width: 28px;
  height: 28px;
  margin-left: 24px;
  margin-right: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-category-icon {
  font-size: 20px !important;
  color: var(--identity-7);
}

.sidebar-category-expand {
  margin-right: 16px;
  margin-left: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-category-expand-mobile {
  margin-right: 16px;
  margin-left: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-category-text {
  font-weight: 500;
}

.sidebar-category-title-container {
  display: flex;
  align-items: center;
}

.sidebar-category-title-and-icon-container {
  display: flex;
  cursor: pointer;
}

.sidebar-subcategories-list {
  display: inline-block;
  margin-top: 16px;
  width: 100%;
}

.sidebar-subcategories-list-mobile {
  display: inline-block;
  padding-top: 8px;
  width: 100%;
  background-color: var(--identity-2);
  border-radius: 0px 0px 10px 10px;
  z-index: 999;
}

.sidebar-subcategory-selected {
  background-color: var(--identity-3);
}

.sidebar-subcategory-title {
  margin-left: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}
