.subscription-filter-icon {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 12px;
  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
}

.filter-buttons {
  display: flex;
  gap: 30px;
}

.filter-button-select-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 220px;
  height: 37px;

  background: linear-gradient(180deg, #8bc730 0%, #9bea5d 100%);
  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 10px;
}

.filter-button-unselect-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 220px;
  height: 37px;

  background: linear-gradient(180deg, #ad3b65 0%, #cf6a8f 100%);
  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 10px;
  color: white;
}

.subscription-edit-button {
  width: 40px;
  height: 40px;
}
