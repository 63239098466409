.period-selection-container {
  display: flex;
  align-items: center;
}

.period-selection-button {
  background-color: var(--identity-4);
  padding: 8px 28px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.period-selection-card {
  background-color: var(--identity-4);
  margin-top: -10px;
  padding: 8px 28px;
  position: absolute;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.period-selection-icon-container {
  align-items: center;
  display: flex;
}

.period-selection-icon {
  font-size: 20px !important;
  color: var(--base-11);
  margin-right: 12px;
  margin-left: 4px;
}

.period-selection-label {
  margin-right: 20px;
}

.period-selection-div {
  position: relative;
}

.period-selection-separator {
  height: 1px;
  background-color: var(--identity-3);
  margin-top: 4px;
  margin-bottom: 8px;
}

.period-selection-label-data-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.period-selection-data-container {
  background-color: var(--identity-1);
  filter: var(--drop-shadow-1);
  padding: 4px 8px;
  border-radius: 10px;
}

.period-selection-done-button {
  background-color: var(--identity-6);
  filter: var(--drop-shadow-1);
  padding: 4px 28px;
  border-radius: 10px;
  color: var(--base-1);
}

.period-selection-date-picker {
  background-color: transparent;
  outline: none;
  color: var(--base-11);
}
