.simple-action-popup {
  display: flex;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
}

.simple-action-popup-backdrop {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(56, 56, 56, 0.75);
  cursor: pointer;
}

.simple-action-popup-container {
  padding: 36px;
}

.simple-action-popup-action-buttons-container {
  display: flex;
  justify-content: space-between;
}

.simple-action-popup-action-buttons-container-right {
  display: flex;
  justify-content: right;
}

.simple-action-popup-first-action-button-container {
  margin-right: 20px;
}

.simple-action-popup-button {
  padding: 8px 36px;

  border-radius: 20px;
  font-size: 18px;

  letter-spacing: 0.025em;

  background: var(--linear-identity-1);
  color: var(--base-1);
  filter: var(--drop-shadow-1);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: width 0.2s;
}

.success-button {
  background: var(--linear-success-1);
  color: var(--base-10);
}

.danger-button {
  background: var(--linear-danger-1);
  color: var(--base-0);
}

.simple-action-popup-title-container {
  margin-bottom: 48px;
}

.simple-action-popup-content-container {
  margin-bottom: 36px;
}

.simple-action-popup-loading-container {
  min-width: 200px;
  display: flex;
  justify-content: center;
}

.simple-action-popup-close-button-container {
  top: -24px;
  position: absolute;
  right: -24px;
  z-index: 30;
  background-color: transparent;
}

.simple-action-popup-close-icon-container {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--base-1);
  border-radius: 50%;
}

.simple-action-popup-close-icon {
  font-size: 28px !important;
  color: var(--identity-6);
}
