h4 {
  font-family: Poppins;
}

.login-screen-container {
  background-color: var(--identity-1);
}

.login-card-container {
  max-width: 800px;
  width: 100%;
}

.login-card-inner-container {
  padding: 32px;
}

.login-card-logo {
  margin-bottom: 54px;
}

.login-card-header {
  margin-bottom: 48px;
}

.login-card-form {
  max-width: 300px;
  width: 100%;
}

.login-screen-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-screen-forgot-password-button {
  height: 21px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-decoration-line: underline;
  color: var(--identity-5);
}

.login-button-container {
  margin-top: 42px;
  margin-bottom: 54px;
  width: 100%;
}
