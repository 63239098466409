.filter-card-content {
  padding: 16px;
}

.filter-card-content-sec1 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.filter-card-search-bar-container {
  margin-bottom: 12px;
  display: flex;
}

.search-bar-button {
  width: 132px;
  height: 44px;
  background: linear-gradient(180deg, #6c367a 0%, #955ba4 100%);

  box-shadow: -1px 2px 4px rgba(117, 117, 117, 0.25);
  border-radius: 10px;
  color: white;

  margin-left: 24px;
}

.search-bar-button:disabled {
  background: #dbdbdb !important;
}

.filter-card-filter-item {
  display: flex;
  align-items: left;
  margin-bottom: 8px;
  cursor: pointer;
}

.filter-card-filter-item-text {
  margin-left: 20px;
  user-select: none;
  max-width: 25ch;
}

.check-box-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-box {
  color: var(--identity-7);
  filter: var(--drop-shadow-1);
  position: absolute;
}

.check-icon {
  font-size: 24px;
  margin-left: 8px;
  margin-bottom: 6px;
  color: var(--identity-6);
}

.check-box-icon {
  font-size: 20px !important;
}
